<template>
    <div>
      <div class="searchBox">
        <div class="searchLeft">
          <div>
            <el-date-picker
              size="medium"
              v-model="searchForm.createTime"
              type="date"
              value-format="timestamp"
              @change="getTableList"
              placeholder="开始时间"
            ></el-date-picker>
          </div>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
          <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>
        </div>
        <div class="searchRight">
  
        </div>
      </div>
      <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="tokenName" label="币种"></el-table-column>
        <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
                <span>{{typeOptions[scope.row.type]}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="expenseCount" label="数量"></el-table-column>
        <el-table-column prop="createTime" label="提交时间" sortable>
          <template slot-scope="scope">
            <span>{{scope.row.createTime | formatDate}}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    </div>
  </template>
  
  <script>
  import * as XLSX from 'xlsx';
  import pagination from '@/components/pagination'
  export default {
    data() {
      return {
        totalNum: 0,
        ruleForm: {status:0},
        searchForm: {
          pageNo: 1,
          pageSize: 10
        },
        tableData: [],
        typeOptions: {
            'task_reward':"任务奖励",
            'room_task_reward':"房间任务奖励",
            'live_reward':"直播奖励",
            'gift_income':"礼物收入",
            'live_duration_reward':"直播时长奖励",
            'live_stay_reward':"直播停留时长奖励",
            'recharge':"充值",
            'receive_gift':"接受礼物",
            'lucky_gift_rebates':"幸运礼物返现",
            'call_income':"通话收入",
            'app_in_apple_recharge':"apple充值",
            'pledge_income':"质押收益",
            'withdraw':"提现",
        }
      };
    },
    components:{pagination},
    created() {
      this.getTableList();
    },
    methods: {
      async exportExcel(){
        var timestamp = 0
        if(!this.searchForm.createTime) {
          var now = new Date();   
          var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());   
          timestamp = startOfDay.getTime()+8*60*60*1000;
        } else {
          timestamp = this.searchForm.createTime+8*60*60*1000
        }
        let res = await this.$http.talkSuccessLogList({...this.searchForm,pageNo:1,pageSize:10000,createTime:timestamp});
        if (res) {
          let tableData = res.result.records;
          const worksheet = XLSX.utils.json_to_sheet(tableData);
          // 创建一个新的工作簿
          const workbook = XLSX.utils.book_new();
          // 将工作表添加到工作簿
          XLSX.utils.book_append_sheet(workbook, worksheet, "People");
          // 导出工作簿（创建 XLSX 文件）
          XLSX.writeFile(workbook, "talkLog.xlsx");
        }
      },
      changeSort(val) {
        if (val.order) {
          this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
          this.searchForm.sortField = val.prop;
        } else {
          this.searchForm.sortOrder = "";
        }
        this.getTableList(true);
      },
      async getTableList(val) {
        if(val) {
          this.searchForm.pageNo = 1
        }
        let searchForm = {...this.searchForm}
        if(this.searchForm.createTime) {
          searchForm.createTime = this.searchForm.createTime+8*60*60*1000
        }

        let res = await this.$http.talkSuccessLogList({...searchForm});
        if (res) {
          this.tableData = res.result.records;
          this.totalNum = res.result.total;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .icon{
    width: 60px;
  }
  </style>
  